<template>
    <v-dialog
        v-model="show"
        max-width="1000px"
        v-on:click:outside="cancelMethod"
        >
        <v-card>
            <v-toolbar
                    color="primary"
                    dark
                    class="text-h5"
                >{{formTitle}}
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon
                        @click="cancelMethod"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
            </v-toolbar>

            <v-card-text>
            <v-form ref="form">
                <v-container>
                <v-row>
                    <v-col>
                    <v-text-field
                        v-model="updatedWorkScheduleDummy.workScheduleDummyName"
                        label="Nome Modello Orario"
                        :rules="[rules.required, rules.counter]"
                        required
                    ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-sheet>
                        <WeekTimesInput
                            v-model="updatedWorkScheduleDummy.defaultWeekWorkTimes"
                        />
                    </v-sheet>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!edit"
                    color="green accent-2"
                    class="px-4"
                    @click="createMethod"
                >
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Salva
                </v-btn>
                <v-btn
                    v-else
                    color="amber lighten-1"
                    class="px-4"
                    @click="updateMethod"
                >
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Modifica
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export const WeekTimesInput = () => import('../../../global/WeekTimesInput.vue')

export default {
    data: function() {
        return {
            updatedCompany: {
                companyName: null
            },
            rules: {
                required: value => !!value || 'Required.',
                counter: value => (value || '').replace(/\s+/g, '').length >= 0 || 'Can\'t be empty',
            },
            updatedWorkScheduleDummy: {
                workScheduleDummyName: '',
                defaultWeekWorkTimes: {
                    mondayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    tuesdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    wednesdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    thursdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    fridayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    saturdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    sundayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    }
                }
            },
            defaultWorkScheduleDummy: {
                workScheduleDummyName: '',
                defaultWeekWorkTimes: {
                    mondayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    tuesdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    wednesdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    thursdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    fridayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    saturdayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    },
                    sundayWorkTimes: {
                        morningTimes: { clockInTime: null, clockOutTime: null, },
                        afternoonTimes: { clockInTime: null, clockOutTime: null, },
                    }
                }
            },
        }
    },
    components: {
        WeekTimesInput
    },
    props: {
        editedWorkScheduleDummy: {
            type: Object,
            default: function() {
                return {}
            }
        },
        show: {
            type: Boolean,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        cancelMethod: function() {
            this.$emit('dialog-close');
        },
        createMethod: function() {
            if (this.$refs.form.validate())
                this.$emit('create-work-schedule-dummy', this.updatedWorkScheduleDummy);
        },
        updateMethod: function() {
            if (this.$refs.form.validate())
                this.$emit('update-work-schedule-dummy', this.editedWorkScheduleDummy.workScheduleDummyName, this.updatedWorkScheduleDummy);
        },
        resetStatus: function() {
            this.updatedWorkScheduleDummy = Object.assign({}, this.defaultWorkScheduleDummy)
            this.$refs.form.reset();
        }
    },
    watch: {
        editedWorkScheduleDummy: function(val) {
            let sVal = JSON.stringify(val);
            if(val && sVal != "{}") {
                this.updatedWorkScheduleDummy = JSON.parse(JSON.stringify(val));
                delete this.updatedWorkScheduleDummy.weekMinutes;
            } else {
                this.updatedWorkScheduleDummy = Object.assign({}, this.defaultWorkScheduleDummy)
            }
        },
        show: function(val) {
            if(!val)
                this.resetStatus();
        }
    },
    computed: {
        formTitle: function() {
            return this.edit ? 'Modifica Modello Orario' : 'Crea Modello Orario'
        }
    }
}
</script>
