import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px"},on:{"click:outside":_vm.cancelMethod},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h5",attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.formTitle)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancelMethod}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Nome Modello Orario","rules":[_vm.rules.required, _vm.rules.counter],"required":""},model:{value:(_vm.updatedWorkScheduleDummy.workScheduleDummyName),callback:function ($$v) {_vm.$set(_vm.updatedWorkScheduleDummy, "workScheduleDummyName", $$v)},expression:"updatedWorkScheduleDummy.workScheduleDummyName"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSheet,[_c('WeekTimesInput',{model:{value:(_vm.updatedWorkScheduleDummy.defaultWeekWorkTimes),callback:function ($$v) {_vm.$set(_vm.updatedWorkScheduleDummy, "defaultWeekWorkTimes", $$v)},expression:"updatedWorkScheduleDummy.defaultWeekWorkTimes"}})],1)],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),(!_vm.edit)?_c(VBtn,{staticClass:"px-4",attrs:{"color":"green accent-2"},on:{"click":_vm.createMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Salva ")],1):_c(VBtn,{staticClass:"px-4",attrs:{"color":"amber lighten-1"},on:{"click":_vm.updateMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Modifica ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }